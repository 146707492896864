import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SocialShare from "./social-share";

const BlogBanner = ({ title, postFields, postUrl }) => {
  return (
    <div className="position-relative my-5 my-md-8">
      <Container>
        <Row className="align-items-md-center justify-content-center">
          <Col xl={7} className="mb-4 mb-lg-0 text-start">
            <h2 className="my-auto text-black">{title}</h2>
            <Row className="mt-5 align-items-md-center">
              <Col md={8}>
                <div className="d-flex align-items-center">
                  {postFields.authorImage?.localFile ? (
                    <GatsbyImage
                      image={
                        postFields.authorImage?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={postFields?.authorImage?.altText}
                      className="rounded-5"
                      style={{ width: "56px", height: "56px" }}
                    />
                  ) : (
                    <div
                      className="bg-light-black rounded-5 d-flex align-items-center justify-content-center"
                      style={{ width: "48px", height: "48px" }}
                    >
                      <StaticImage
                        src={"../images/placeholder.png"}
                        alt="Sprout Tuition"
                        height={44}
                        layout="fixed"
                      />
                    </div>
                  )}
                  <div className="ms-3">
                    <span className="axiforma-semibold text-black mb-0">
                      {postFields.authorName}
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="text-black mb-0 axiforma-regular">
                        {" "}
                        {postFields.date}
                      </span>
                      {postFields.duration && (
                        <>
                          <span className="px-2" style={{ marginTop: "-6px" }}>
                            .
                          </span>
                          <span className="text-black mb-0 axiforma-regular">
                            {postFields.duration}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} className="text-md-end mt-4 mt-md-0">
                <SocialShare postTitle={title} postUrl={postUrl} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="pt-3 pt-md-5 pt-xl-7">
            <GatsbyImage
              image={
                postFields.featureImage?.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={postFields.featureImage?.altText}
              className="border-2 border-white border rounded-5 banner-image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogBanner;
