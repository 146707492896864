import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import BlogBanner from "../components/blog-banner";
import { SafeHtmlParser } from "../components/safe-html-parser";
import Form from "react-bootstrap/Form";
import { Link } from "gatsby";
import Button from "react-bootstrap/Button";
import HeroSection from "../components/hero-homepage-section";
import HeroPageSection from "../components/hero-page-section";
import SectionLayout2 from "../components/section-layout-2";
import SectionLayout3 from "../components/section-layout-3";
import SectionLayout4 from "../components/section-layout-4";
import SectionLayout5 from "../components/section-layout-5";
import SectionLayout6 from "../components/section-layout-6";
import SectionLayout7 from "../components/section-layout-7";
import SectionLayout8 from "../components/section-layout-8";
import SectionLayout9 from "../components/section-layout-9";
import SectionLayout10 from "../components/section-layout-10";
import SectionLayout11 from "../components/section-layout-11";
import SectionLayout12 from "../components/section-layout-12";
import SectionLayout13 from "../components/section-layout-13";
import SectionLayout14 from "../components/section-layout-14";
import SectionLayout15 from "../components/section-layout-15";
import SectionLayout16 from "../components/section-layout-16";
import SectionLayout17 from "../components/section-layout-17";
import SectionLayout18 from "../components/section-layout-18";
import SectionLayout19 from "../components/section-layout-19";
import uuid4 from "uuid4";

const BlogPostTemplate = ({ data: { post, site, allWpCourse, allWpPost } }) => {
  const siteUrl = site.siteMetadata.siteUrl;
  const postUrl = `${site.siteMetadata.siteUrl}/resources/${post.slug}`;

  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog",
        item: {
          url: `${siteUrl}/blog`,
          id: `${siteUrl}/blog`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: `${post.title}`,
        item: {
          url: `${siteUrl}/blog/${post.slug}`,
          id: `${siteUrl}/blog/${post.slug}`,
        },
      },
    ],
  };

  const {
    pageFields: { pageSections },
  } = post;

  console.log(pageSections);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={post.seoFieldGroups?.metaTitle}
        description={post.seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/blog/${post.slug}`,
          title: `${post.seoFieldGroups?.openGraphTitle}`,
          description: `${post.seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${post.seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${post.seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${post.seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${post.seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <BlogBanner
          title={post.title}
          postFields={post.postFields}
          postUrl={postUrl}
        />
        <section className="my-5 my-md-8">
          <Container>
            <Row className="gx-6">
              <Col lg={8} className="blog-default-content">
                {post.postFields.body && (
                  <SafeHtmlParser htmlContent={post.postFields.body} />
                )}
              </Col>
              <Col lg={4} id="subscribe-form">
                <div className="border border-dark-gray rounded-3 p-4">
                  <h5 className="mb-0 lh-base">Subscribe to newsletter</h5>
                  <p className="fs-6 text-black py-3 mb-0">
                    Subscribe to receive the latest blog posts to your inbox
                    every week.
                  </p>
                  <Form className="w-100" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        className="mr-sm-1 bg-lighter-grey py-12 rounded-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <Button
                        type="submit"
                        className="w-100 h-100 mt-3 py-12"
                        variant="secondary"
                      >
                        Subscribe
                      </Button>
                    </div>
                  </Form>
                  <span style={{ fontSize: "12px" }}>
                    By subscribing you agree to with our{" "}
                    <Link to="/policy/privacy-policy">Privacy Policy</Link>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {pageSections &&
          pageSections.length > 0 &&
          pageSections.map((sectionInfo) => (
            <>
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_HeroHomepageSection" && (
                <HeroSection
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  backgroundType={sectionInfo?.background}
                  backgroundImage={
                    sectionInfo.backgroundImage?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  backgroundVideo={sectionInfo?.backgroundVideo}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_HeroPageSection" && (
                <HeroPageSection
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  alignment={sectionInfo?.alignment}
                  backgroundImage={
                    sectionInfo.backgroundImage?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout2" && (
                <SectionLayout2
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  backgroundColor={sectionInfo?.backgroundColor}
                  alignment={sectionInfo?.alignment}
                  hasBorder={sectionInfo?.hasBorder}
                  image={sectionInfo?.image}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout3" && (
                <SectionLayout3
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  backgroundImage={
                    sectionInfo.backgroundImage?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout4" && (
                <SectionLayout4
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout5" && (
                <SectionLayout5
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  subheading={sectionInfo?.subheading}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout6" && (
                <SectionLayout6
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  backgroundImage={
                    sectionInfo.backgroundImage?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  hasTwoColumn={sectionInfo?.hasTwoColumn}
                  hasContentRight={sectionInfo?.hasContentRight}
                  alignment={sectionInfo?.alignment}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout7" && (
                <SectionLayout7
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout8" && (
                <SectionLayout8
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout9" && (
                <SectionLayout9
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  hasTab={sectionInfo?.hasTab}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout10" && (
                <SectionLayout10
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  items={sectionInfo?.items}
                  image={sectionInfo?.image}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout11" && (
                <SectionLayout11
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  subheading={sectionInfo?.subheading}
                  items={allWpCourse.nodes}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout12" && (
                <SectionLayout12
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  subheading={sectionInfo?.subheading}
                  items={allWpPost.nodes}
                  viewAllButtonText={sectionInfo?.viewAllButtonText}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout13" && (
                <SectionLayout13
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  reviews={sectionInfo?.reviews}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout14" && (
                <SectionLayout14
                  heading={sectionInfo?.heading}
                  content={sectionInfo?.body}
                  faqData={sectionInfo?.faqs}
                  button={sectionInfo?.button}
                  bottomHeading={sectionInfo?.bottomHeading}
                  bottomContent={sectionInfo?.bottomContent}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout15" && (
                <SectionLayout15
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout16" && (
                <SectionLayout16
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  monthlyPricingItems={sectionInfo?.monthlyPricingItems}
                  perTermPricingItems={sectionInfo?.perTermPricingItems}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout17" && (
                <SectionLayout17
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout18" && (
                <SectionLayout18
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  content={sectionInfo?.body}
                  button={sectionInfo?.button}
                  button2={sectionInfo?.button2}
                  items={sectionInfo?.items}
                  key={uuid4()}
                />
              )}
              {sectionInfo.fieldGroupName ===
                "Post_Pagefields_PageSections_SectionLayout19" && (
                <SectionLayout19
                  content={sectionInfo?.body}
                  heading={sectionInfo?.heading}
                  subheading={sectionInfo?.subheading}
                  key={uuid4()}
                />
              )}
            </>
          ))}
      </Layout>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        terms {
          nodes {
            name
          }
        }
        title
        uri
        slug
        postFields {
          authorName
          authorImage {
            altText
            localFile {
              publicURL
              childImageSharp {
                original {
                  height
                  width
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 48
                )
              }
            }
          }
          body
          date
          duration
          excerpt
          featureImage {
            altText
            localFile {
              publicURL
              childImageSharp {
                original {
                  height
                  width
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    allWpCourse(
      limit: 6
      sort: { fields: date, order: DESC }
      filter: { courseFields: { hasFree: { eq: true } } }
    ) {
      nodes {
        title
        terms {
          nodes {
            name
          }
        }
        slug
        courseFields {
          hasFree
          excerpt
          featureImage {
            altText
            localFile {
              publicURL
              childImageSharp {
                original {
                  height
                  width
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          coursePdf {
            mediaItemUrl
          }
          body
        }
      }
    }
    post: wpPost(id: { eq: $id }) {
      id
      slug
      title
      date
      postFields {
        authorName
        authorImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 56
              )
            }
          }
        }
        body
        date
        duration
        excerpt
        featureImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
      terms {
        nodes {
          slug
          name
        }
      }
      pageFields {
        pageSections {
          ... on WpPost_Pagefields_PageSections_HeroHomepageSection {
            background
            fieldGroupName
            heading
            subheading
            body
            button {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            backgroundVideo {
              mediaItemUrl
            }
          }
          ... on WpPost_Pagefields_PageSections_HeroPageSection {
            fieldGroupName
            heading
            subheading
            body
            alignment
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            button {
              target
              title
              url
            }
            button2 {
              title
              url
              target
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout2 {
            alignment
            backgroundColor
            fieldGroupName
            body
            heading
            hasBorder
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            button {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
            subheading
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout3 {
            body
            fieldGroupName
            button {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            heading
            subheading
            items {
              body
              fieldGroupName
              heading
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 40
                    )
                  }
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout4 {
            body
            fieldGroupName
            heading
            button {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout5 {
            body
            fieldGroupName
            heading
            subheading
            items {
              description
              heading
              icon {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 32
                    )
                  }
                }
              }
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout6 {
            alignment
            body
            fieldGroupName
            hasTwoColumn
            hasContentRight
            heading
            button {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout7 {
            body
            fieldGroupName
            button {
              target
              title
              url
            }
            heading
            items {
              day
              price
              time
              title
              type
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout8 {
            fieldGroupName
            heading
            subheading
            items {
              body
              heading
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 100
                    )
                  }
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout9 {
            body
            fieldGroupName
            hasTab
            heading
            subheading
            items {
              address
              addressLink
              fieldGroupName
              heading
              mapLink
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout10 {
            body
            fieldGroupName
            heading
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            items {
              body
              heading
              icon {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 46
                    )
                  }
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout11 {
            body
            fieldGroupName
            heading
            subheading
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout12 {
            body
            fieldGroupName
            heading
            subheading
            viewAllButtonText
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout13 {
            body
            fieldGroupName
            heading
            reviews {
              ... on WpReview {
                id
                slug
                title
                reviewFields {
                  city
                  name
                  postcode
                  review
                  reviewStar
                  image {
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        original {
                          height
                          width
                        }
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                          width: 56
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout14 {
            body
            bottomContent
            fieldGroupName
            heading
            bottomHeading
            button {
              target
              title
              url
            }
            faqs {
              ... on WpFaq {
                title
                faqFields {
                  body
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout15 {
            body
            fieldGroupName
            heading
            items {
              ... on WpPost {
                id
                title
                slug
                postFields {
                  authorName
                  date
                  authorImage {
                    altText
                  }
                  body
                  excerpt
                  duration
                  featureImage {
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        original {
                          height
                          width
                        }
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
            }
            subheading
            button {
              target
              title
              url
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout16 {
            fieldGroupName
            heading
            subheading
            monthlyPricingItems {
              price
              type
              icon {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 48
                    )
                  }
                }
              }
              fieldGroupName
              features {
                heading
              }
              link {
                target
                title
                url
              }
            }
            perTermPricingItems {
              price
              type
              fieldGroupName
              features {
                heading
              }
              icon {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout17 {
            body
            fieldGroupName
            heading
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout18 {
            body
            fieldGroupName
            heading
            button {
              target
              title
              url
            }
            button2 {
              title
              target
              url
            }
            subheading
            items {
              heading
              body
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on WpPost_Pagefields_PageSections_SectionLayout19 {
            heading
            subheading
            body
            fieldGroupName
          }
        }
      }
      seoFieldGroups {
        metaTitle
        metaDescription
        localBusinessSchema
        openGraphDescription
        openGraphTitle
        productSchema
        image {
          altText
          publicUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                layout: CONSTRAINED
                placeholder: BLURRED
              )
              original {
                width
                height
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
